export const isObjectWithKeyOfType = <
  KeyName extends string,
  Value extends
    | 'string'
    | 'number'
    | 'bigint'
    | 'boolean'
    | 'symbol'
    | 'undefined'
    | 'object'
    | 'function'
    | 'unknown',
>(
  obj: unknown,
  key: KeyName,
  type: Value,
): obj is {
  [Key in KeyName]: Value extends 'string' ? string
  : Value extends 'number' ? number
  : Value extends 'bigint' ? bigint
  : Value extends 'boolean' ? boolean
  : Value extends 'symbol' ? symbol
  : Value extends 'undefined' ? undefined
  : Value extends 'object' ? object
  : Value extends 'function' ? (...args: unknown[] | never) => unknown
  : unknown;
} =>
  !!obj &&
  typeof obj === 'object' &&
  key in obj &&
  typeof (obj as { [Key in KeyName]: unknown })[key] === type;
