import { Helmet } from 'react-helmet';
import { useTheme } from 'src/contexts/theme-context';
import { hex } from 'src/tools/colors/hex';

function ThemedMeta() {
  const theme = useTheme();

  return (
    <Helmet>
      <meta
        name="theme-color"
        content={
          theme.lowercaseTheme === 'dark' ? hex('black') : hex('hyundaiBlue')
        }
      />
      <link
        rel="manifest"
        href={`/icons/${theme.lowercaseTheme}/site.webmanifest`}
      />

      {/* Apple */}
      <meta name="apple-mobile-web-app-title" content="Command Center" />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-60x60.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-144x144.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-152x152.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/icons/${theme.lowercaseTheme}/apple-touch-icon-180x180.png`}
      />
      {/* <link
        rel="mask-icon"
        href={`/icons/safari-pinned-tab.svg?v=2`}
        color={hex('motionalPurple')}
      /> */}

      {/* Android */}
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/icons/${theme.lowercaseTheme}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="194x194"
        href={`/icons/${theme.lowercaseTheme}/favicon-194x194.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={`/icons/${theme.lowercaseTheme}/android-chrome-192x192.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/icons/${theme.lowercaseTheme}/favicon-16x16.png`}
      />
      <link
        rel="shortcut icon"
        href={`/icons/${theme.lowercaseTheme}/favicon.ico`}
      />

      {/* Microsoft */}
      <meta name="application-name" content="Command Center" />
      <meta name="msapplication-TileColor" content={hex('motionalPurple')} />
      <meta
        name="msapplication-TileImage"
        content={`/icons/${theme.lowercaseTheme}/mstile-144x144.png`}
      />
      <meta
        name="msapplication-config"
        content={`/icons/${theme.lowercaseTheme}/browserconfig.xml`}
      />
    </Helmet>
  );
}

export default ThemedMeta;
