import { Partner as ConciergePartner } from '@motional-cc/fe/interface/api/api-concierge';
import {
  Vehicles as ApiServerVehicles,
  VehiclePartner,
} from '@motional-cc/fe/interface/api/api-server';
import {
  PartnerJobPreference,
  PartnerName,
} from '@motional-cc/fe/interface/api/armada';
import {
  PartnerUpdate,
  Vehicles as VssVehicles,
} from '@motional-cc/fe/interface/api/vehicle-status-service';
import t from '@motional-cc/fe/tools/translate';
import { useMemo } from 'react';
import { apiServerPaths } from 'src/api/api-server';
import { armadaApi } from 'src/api/armada';
import { useApi, useMutateApi } from 'src/api/hooks/service';
import { fleetPaths } from 'src/api/vehicle-status-service';
import BorderedCard from 'src/components/common/Card/BorderedCard';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import IconButton from 'src/components/common/IconButton';
import { Radio, RadioGroup } from 'src/components/common/Radio';
import Tooltip from 'src/components/common/Tooltip';
import { useMessages } from 'src/components/Messages/messages-context';
import { ApiError } from 'src/interface/command-center/unsorted-classes';
import { isIncompleteStatus } from 'src/interface/command-center/unsorted-type-guards';
import { PartialBy } from 'src/interface/utility';
import PartnerLogo from 'src/static/img/PartnerLogo';
import { trackAction } from 'src/tools/analytics';
import { objectEntries } from 'src/tools/object/objectEntries';
import { objectFromEntries } from 'src/tools/object/objectFromEntries';
import { renderPartnerLabel } from 'src/tools/translate';
import {
  useCannotEditPartnerPreferencesReason,
  useCannotEditPartnerVehicleReason,
} from 'src/tools/vehicle/useCannotEditVehiclePartnerReason';
import SelectPartnerJobPreferences from './SelectPartnerJobPreferences';

const TIME_TO_PERSIST_DATA = 1000;

export type Partner = PartialBy<VehiclePartner, 'partner_vehicle_id'> &
  Pick<ConciergePartner, 'carId' | 'jobPreferences'>;

interface Props {
  // carId is used to avoid assuming a vehicle doesn’t exist while loading
  carId?: string;
  carVin?: string;
  onEditPartnerClick: (partner: Partner | undefined) => void;
  onChangeSuccess: () => void;
}

function ManageVehiclePartners({
  carId,
  carVin,
  onEditPartnerClick,
  onChangeSuccess,
}: Props) {
  const { showMessage } = useMessages();
  const {
    vehicle,
    status: vehicleLoadingStatus,
    error: vehicleLoadError,
  } = armadaApi.useVehicle(carId);
  const { mutate: changeOdm, isLoading } = useMutateApi<
    VssVehicles.UpdateVehiclePartners.ResponseBody,
    VssVehicles.UpdateVehiclePartners.RequestBody
  >(fleetPaths.Vehicles.UpdateVehiclePartners(carId ?? ''), 'PUT');

  const { result: vehiclePartners, status: vehiclePartnersStatus } =
    useApi<ApiServerVehicles.PartnersDetail.ResponseBody>(
      apiServerPaths.Vehicles.PartnersDetail(carVin ?? ''),
    );

  const partners: Partner[] | undefined = useMemo(
    () =>
      vehicle?.partners ?
        objectEntries(vehicle.partners).map(([partnerName, partner]) => ({
          ...partner,
          name: partnerName,
        }))
      : vehiclePartners ? vehiclePartners
      : undefined,
    [vehicle?.partners, vehiclePartners],
  );

  const {
    message: cannotChangeVehiclePartnerSettingsReason,
    type: cannotEditPartnerPreferencesType,
  } = useCannotEditPartnerPreferencesReason({
    vehicle,
    loadError: vehicleLoadError,
    loadStatus: vehicleLoadingStatus,
  });
  const hasPermissionToChangeVehiclePartnerSettings =
    cannotEditPartnerPreferencesType !== 'permission';

  const {
    message: cannotEditPartnerVehicleReason,
    type: cannotEditPartnerVehicleType,
  } = useCannotEditPartnerVehicleReason({
    vehicle,
    loadError: vehicleLoadError,
    loadStatus: vehicleLoadingStatus,
  });
  const hasPermissionToEditPartnerVehicle =
    cannotEditPartnerVehicleType !== 'permission';

  const handleChangeActivePartner = async (newPartner: PartnerName) => {
    if (!partners) return;

    try {
      const vehiclePartners = vehicle?.partners;
      await changeOdm({
        currentPartner: newPartner,
        partners:
          vehiclePartners ?
            objectFromEntries(
              objectEntries(vehiclePartners).map(([partnerName, partner]) => [
                partnerName,
                {
                  jobPreferences: partner?.jobPreferences ?? undefined,
                },
              ]),
            )
          : {},
      });

      showMessage({
        type: 'success',
        title: 'Sucessfully updated current partner',
        description: 'Please wait a few seconds for it to update visually',
      });
    } catch (_error) {
      const error = _error as ApiError;
      showMessage({
        type: 'error',
        title: 'Failed to update current partner',
        description:
          (error.code &&
            t(`errorCodes.${error.code}`, {
              partnerLabel: renderPartnerLabel(newPartner),
            })) ||
          error.message ||
          'Current partner failed to update',
      });
    }

    setTimeout(() => {
      onChangeSuccess();
    }, TIME_TO_PERSIST_DATA);
  };

  const handleEditPartnerClick =
    (newPartner: NonNullable<typeof partners>[number]) => () => {
      if (cannotEditPartnerVehicleReason) return;

      onEditPartnerClick(newPartner);

      trackAction('Vehicle partners', { action: 'Click edit partner' });
    };

  const handlePartnerServiceChange =
    (partner: Partner) => async (newService: PartnerJobPreference[]) => {
      if (cannotChangeVehiclePartnerSettingsReason) return;

      try {
        const newPartners: VssVehicles.UpdateVehiclePartners.RequestBody['partners'] =

            !vehicle?.partners ?
              {}
            : objectFromEntries(
                objectEntries(vehicle?.partners).map(
                  ([partnerName, partnerDetails]): [
                    PartnerName,
                    PartnerUpdate | undefined,
                  ] => [partnerName, partnerDetails ?? undefined],
                ),
              );
        await changeOdm({
          currentPartner: vehicle?.currentPartner ?? null,
          partners: {
            ...newPartners,
            [partner.name]: {
              ...(newPartners?.[partner.name] ?? {}),
              jobPreferences: newService,
            },
          },
        });

        showMessage({
          type: 'success',
          title: 'Sucessfully updated partner service',
          description: 'Please wait a few seconds for it to update visually',
        });
      } catch (_error) {
        const error = _error as ApiError;

        showMessage({
          type: 'error',
          title: 'Partner service failed to update',
          description:
            (error.code &&
              t(`errorCodes.${error.code}`, {
                partnerLabel: renderPartnerLabel(partner.name, newService),
              })) ||
            error.message ||
            'Partner service failed to update',
        });
      }

      setTimeout(() => {
        onChangeSuccess();
      }, TIME_TO_PERSIST_DATA);
    };

  return (
    <div className="vehicle-management__partners">
      <header className="vehicle-management__details-header">
        <hgroup className="vehicle-management__details-hgroup">
          <h4 className="vehicle-management__details-title">
            Registered partners
          </h4>
          <p className="vehicle-management__details-strapline">
            Change the active partner by clicking on the appropriate partner’s
            card below. Within each card, select which service the partner is
            using with this vehicle. You can also edit or delete each partner
            separately.
          </p>
        </hgroup>
      </header>

      {/* Separated the loading and display as the data comes from 2 places */}
      {isIncompleteStatus(vehiclePartnersStatus) && <FullWidthLoader />}
      {partners && (
        <RadioGroup
          name="activePartner"
          value={vehicle?.currentPartner}
          onChange={handleChangeActivePartner}
        >
          {[
            ...((
              !vehicle ||
              // if a partner is assigned and can’t be changed, there’s no need to show the motional option
              (!hasPermissionToChangeVehiclePartnerSettings &&
                vehicle?.currentPartner)
            ) ?
              []
            : [
                <BorderedCard
                  key="motional"
                  className="vehicle-management__partner-card"
                  color={
                    vehicle && !vehicle.currentPartner ? 'focus' : undefined
                  }
                >
                  {hasPermissionToChangeVehiclePartnerSettings && (
                    <div className="vehicle-management__partner-radio-container">
                      <Radio
                        hideLabel
                        name="activePartner"
                        value={null}
                        label="Motional"
                        disabled={isLoading}
                      />
                    </div>
                  )}

                  <div className="vehicle-management__settings-container">
                    <header className="vehicle-management__details-header">
                      <hgroup className="vehicle-management__details-hgroup">
                        <PartnerLogo
                          partnerName="motional"
                          className="vehicle-management__details-icon"
                        />

                        <h4 className="vehicle-management__details-title">
                          Motional
                        </h4>
                        <p className="vehicle-management__details-vehicle-id">
                          No active partner
                        </p>
                      </hgroup>
                    </header>
                  </div>
                </BorderedCard>,
              ]),

            ...(!partners ?
              []
            : partners
                .filter(
                  (partner) => partner?.carId || partner?.partner_vehicle_id,
                )
                .map((partner) => (
                  <BorderedCard
                    key={partner.name}
                    className="vehicle-management__partner-card"
                    color={
                      vehicle?.currentPartner === partner.name ?
                        'focus'
                      : undefined
                    }
                  >
                    {hasPermissionToChangeVehiclePartnerSettings && (
                      <Tooltip
                        content={
                          !partner.jobPreferences?.length ?
                            'A service needs to be chosen before assigning this partner'
                          : ''
                        }
                      >
                        <div className="vehicle-management__partner-radio-container">
                          <Radio
                            hideLabel
                            name="activePartner"
                            value={partner.name}
                            label={partner.name}
                            disabled={
                              !partner.jobPreferences?.length || isLoading
                            }
                          />
                        </div>
                      </Tooltip>
                    )}

                    <div className="vehicle-management__settings-container">
                      <header className="vehicle-management__details-header">
                        <hgroup className="vehicle-management__details-hgroup">
                          <PartnerLogo
                            partnerName={partner.name}
                            service={partner.jobPreferences?.[0]}
                            className="vehicle-management__details-icon"
                          />

                          <h4 className="vehicle-management__details-title">
                            {renderPartnerLabel(
                              partner.name,
                              partner.jobPreferences,
                            )}
                          </h4>
                          {partner.jobPreferences && (
                            <p className="vehicle-management__details-state">
                              {t(
                                `partnerService.${partner.jobPreferences[0]}`,
                              ) || partner.jobPreferences[0]}
                            </p>
                          )}
                          <p className="vehicle-management__details-vehicle-id">
                            {partner.carId || partner.partner_vehicle_id}
                          </p>
                        </hgroup>

                        {hasPermissionToEditPartnerVehicle && (
                          <Tooltip content={cannotEditPartnerVehicleReason}>
                            <IconButton
                              allowInteractionWhenDisabled
                              iconName="SquareWithPencil"
                              className="vehicle-management__details-action"
                              onClick={handleEditPartnerClick(partner)}
                              disabled={
                                isLoading || !!cannotEditPartnerVehicleReason
                              }
                            />
                          </Tooltip>
                        )}
                      </header>

                      {vehicle &&
                        hasPermissionToChangeVehiclePartnerSettings && (
                          <SelectPartnerJobPreferences
                            hideSingleOption
                            onChange={handlePartnerServiceChange(partner)}
                            partner={partner.name}
                            type="radio"
                            disabled={isLoading}
                            value={
                              partner.jobPreferences?.[0] ?
                                [partner.jobPreferences[0]]
                              : undefined
                            }
                          />
                        )}
                    </div>
                  </BorderedCard>
                ))),
          ]}
        </RadioGroup>
      )}
    </div>
  );
}

export default ManageVehiclePartners;
