import clsx from 'clsx';
import difference from 'lodash/difference';
import {
  KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import Popover, { PopoverWidth } from 'src/components/common/Popover';
import { isNotNullish } from 'src/tools/types';
import { AutocompleteProps, CommonProps } from './interface';
import SelectPopover from './SelectPopover';
import { getOptionKey } from './utils';

const EMPTY_OPTIONS = ['EMPTY_OPTION'] as const;

type SelectBaseProps<Option> = {
  className?: string;
  searchPlaceholder?: string;
  type?: 'checkbox' | 'radio';
  placement?: Parameters<typeof Popover>[0]['placement'];
  isOpen: boolean;
  isLoading?: boolean;
  closeOnSelect?: boolean;
  selectedOptions?: Readonly<Option[]>;
  disabledOptions?: Readonly<Option[]>;
  toggleElement: HTMLElement;
  anchorElement: HTMLElement;
  popoverWidth?: PopoverWidth;

  onSelect: (option: Option) => void;
  onClose: () => void;
  onOpen: () => void;
  onRemoveLast?: () => void;
  onClearSelection?: () => void;
};

type Props<Option> = Omit<CommonProps<Option>, 'label'> &
  SelectBaseProps<Option> &
  Partial<AutocompleteProps>;

function SelectBase<Option>({
  className,
  isOpen,
  closeOnSelect,
  selectedOptions,
  disabledOptions,
  toggleElement,

  options: optionsProps,
  optionKeyPath,
  popoverWidth,

  onSelect,
  onClose,
  onOpen,
  onRemoveLast,

  ...popoverProps
}: Props<Option>) {
  const optionsListId = useId();

  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1);
  const prevIsOpen = useRef(isOpen);

  const options = useMemo(() => optionsProps ?? [], [optionsProps]);

  const selectIndex = useCallback(
    (index: number) => {
      if (index === -1) return;

      onSelect(options[index]);
    },
    [onSelect, options],
  );

  const firstSelectedNonDisabledOptionIndex = useMemo(
    () =>
      !selectedOptions?.length ?
        -1
      : options.indexOf(difference(selectedOptions, disabledOptions ?? [])[0]),
    [disabledOptions, options, selectedOptions],
  );

  const firstNonDisabledOptionIndex = useMemo(
    () => options.findIndex((option) => !disabledOptions?.includes(option)),
    [disabledOptions, options],
  );

  const lastNonDisabledOptionIndex = useMemo(
    () => options.findLastIndex((option) => !disabledOptions?.includes(option)),
    [disabledOptions, options],
  );

  useEffect(
    function doStuffUponOpening() {
      if (isOpen || prevIsOpen.current || highlightedOptionIndex > -1) {
        return;
      }

      setHighlightedOptionIndex(
        firstSelectedNonDisabledOptionIndex > -1 ?
          firstSelectedNonDisabledOptionIndex
        : firstNonDisabledOptionIndex,
      );
    },
    [
      isOpen,
      disabledOptions,
      options,
      selectedOptions,
      highlightedOptionIndex,
      firstSelectedNonDisabledOptionIndex,
      firstNonDisabledOptionIndex,
    ],
  );

  useEffect(
    function addActionEventsToToggle() {
      if (!toggleElement) return;

      const keyDownCallback = (event: KeyboardEvent) => {
        if (['Tab'].includes(event.key)) {
          if (isOpen) {
            event.preventDefault();
          }
        }

        if (['Escape'].includes(event.key)) {
          setHighlightedOptionIndex(-1);
          onClose();

          if (isOpen) {
            event.preventDefault();
            event.stopPropagation();
          }
        }

        if (['Enter'].includes(event.key)) {
          if (isOpen) {
            event.preventDefault();
            if (disabledOptions?.includes(options[highlightedOptionIndex])) {
              return;
            }
            selectIndex(highlightedOptionIndex);
          }
        }

        if ([' ', 'Spacebar'].includes(event.key)) {
          event.preventDefault();
          if (isOpen) {
            selectIndex(highlightedOptionIndex);
          } else {
            onOpen();
          }
        }

        if (event.key === 'Backspace') {
          if (onRemoveLast) {
            event.preventDefault();
            onRemoveLast();
          }
        }

        if (['ArrowUp'].includes(event.key)) {
          event.preventDefault();
          if (!isOpen) {
            onOpen();
            setHighlightedOptionIndex(lastNonDisabledOptionIndex);
          } else {
            setHighlightedOptionIndex(Math.max(highlightedOptionIndex - 1, 0));
          }
        }

        if (['ArrowDown'].includes(event.key)) {
          event.preventDefault();
          if (!isOpen) {
            onOpen();
            setHighlightedOptionIndex(
              firstSelectedNonDisabledOptionIndex > -1 ?
                firstSelectedNonDisabledOptionIndex
              : firstNonDisabledOptionIndex,
            );
          } else {
            setHighlightedOptionIndex(
              Math.min(highlightedOptionIndex + 1, options.length - 1),
            );
          }
        }
      };

      toggleElement.addEventListener('keydown', keyDownCallback);

      return () => {
        toggleElement.removeEventListener('keydown', keyDownCallback);
      };
    },
    [
      highlightedOptionIndex,
      lastNonDisabledOptionIndex,
      firstNonDisabledOptionIndex,
      firstSelectedNonDisabledOptionIndex,
      isOpen,
      toggleElement,
      disabledOptions,
      options,
      onOpen,
      onClose,
      selectIndex,
      onRemoveLast,
    ],
  );

  const handleOptionHighlight = (option: Option) => {
    const key = getOptionKey(option, optionKeyPath);
    const optionIndex = options.findIndex(
      (option) => getOptionKey(option, optionKeyPath) === key,
    );
    if (optionIndex > -1) {
      setHighlightedOptionIndex(optionIndex);
    }
  };

  const handleOptionSelect = (option: Option) => {
    if (isNotNullish(option)) {
      onSelect(option);
    }
    if (closeOnSelect) {
      setHighlightedOptionIndex(-1);
      onClose();
    }
    handleOptionHighlight(option);
  };

  const handleListKeyDown = (event: ReactKeyboardEvent) => {
    if (['Tab'].includes(event.key)) {
      event.preventDefault();
    }

    if (['Escape'].includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();

      setHighlightedOptionIndex(-1);
      onClose();
    }

    if (['Enter'].includes(event.key)) {
      event.preventDefault();

      selectIndex(highlightedOptionIndex);
    }

    if ([' ', 'Spacebar'].includes(event.key)) {
      event.preventDefault();

      selectIndex(highlightedOptionIndex);
    }

    if (event.key === 'Backspace') {
      if (onRemoveLast) {
        event.preventDefault();
        onRemoveLast();
      }
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();

      setHighlightedOptionIndex(Math.max(highlightedOptionIndex - 1, 0));
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();

      setHighlightedOptionIndex(
        Math.min(highlightedOptionIndex + 1, options.length - 1),
      );
    }
  };

  return (
    <SelectPopover
      // external props
      {...popoverProps}
      width={popoverWidth}
      tooltipId={optionsListId}
      onKeyDown={handleListKeyDown}
      onOptionSelect={handleOptionSelect}
      onOptionHighlight={handleOptionHighlight}
      highlightedOptionIndex={highlightedOptionIndex}
      isOpen={isOpen}
      onClose={onClose}
      optionKeyPath={optionKeyPath}
      selectedOptions={selectedOptions ?? []}
      className={clsx(className, 'select-base')}
      options={
        options?.length ? options : (
          (EMPTY_OPTIONS as unknown as readonly Option[])
        )
      }
      disabledOptions={
        options?.length ?
          disabledOptions ?? []
        : (EMPTY_OPTIONS as unknown as readonly Option[])
      }
      renderListOption={
        options?.length ? popoverProps.renderListOption : () => 'No options'
      }
    />
  );
}

export default SelectBase;
