import { Vehicles as ConciergeVehicles } from '@motional-cc/fe/interface/api/api-concierge';
import {
  AlertSeverityLevel,
  Fleets,
  urls,
  Vehicles as ArmadaVehicles,
} from '@motional-cc/fe/interface/api/armada';
import { Operators } from '@motional-cc/fe/interface/api/vehicle-status-service';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useState } from 'react';
import { useApi, useInfiniteApi } from 'src/api/hooks/service';
import { armada } from 'src/api/hosts';
import { userApi } from 'src/api/user';
import { useUserProfileSetting } from 'src/contexts/profile-settings-context';
import { useUrlSettings } from 'src/contexts/url-settings-context';
import { FleetFilter } from 'src/interface/armada';
import { REGIONS } from 'src/interface/command-center/unsorted-type-arrays';
import { QueryParams } from 'src/interface/utility';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import { conciergePaths } from './concierge';
import { combineQueryStatuses, convertToAbsolutePaths } from './utils';
import { fleetPaths } from './vehicle-status-service';

export const armadaPaths = convertToAbsolutePaths(armada, urls);

interface UseVehiclesOptions<FleetId extends string | undefined> {
  fleetId?: FleetId;
  filters?: FleetFilter[];
  regions?: string[];
  params?: QueryParams;
  enabled?: boolean;
  pageSize?: number;
  keepPreviousData?: boolean;
  refetchInterval?: number | false;
  queryKey?: string[];
}

// TODO: wait for armada /vehicles to be paginated (with cursor)
const useVehicles = <FleetId extends string | undefined>({
  filters: paramsFilters = [],
  fleetId,
  regions = [],
  params: paramsParams,
  enabled = true,
  pageSize = 300,
  refetchInterval = false,
  keepPreviousData = true,
  queryKey,
}: UseVehiclesOptions<FleetId> = {}) => {
  type ResponseObject =
    FleetId extends string ? Fleets.VehiclesDetail.ResponseBody
    : ArmadaVehicles.VehiclesList.ResponseBody;
  const basePath =
    typeof fleetId === 'string' ?
      armadaPaths.Fleets.VehiclesDetail(fleetId)
    : armadaPaths.Vehicles.VehiclesList();
  const filters = [...paramsFilters];
  if (regions.length) {
    filters.push({
      key: 'region',
      check: 'is equal to',
      values: regions,
    });
  }
  const params = {
    ...paramsParams,
  };
  if (filters?.length) {
    params.filters = JSON.stringify(filters);
  }

  const response = useInfiniteApi<ResponseObject>(basePath, {
    isKnownToBeAnIrregularApi: true,
    params,
    keepPreviousData,
    enabled,
    pageSize,
    refetchInterval,
    queryKey,
  });

  return {
    ...response,
    result: enabled ? response.result : null,
  };
};

const VEHICLE_REFETCH_INTERVAL = inMilliseconds(3, 'seconds');
const inactiveFilter: FleetFilter = {
  key: 'connectedServerTimestamp',
  check: 'is greater than',
  values: [
    Math.trunc((Number(new Date()) - inMilliseconds(30, 'days')) / 1000),
  ],
};
const useFleetVehicles = ({
  enabled: enabledProps = true,
  useUrlFilters: useUrlFiltersProps,
  showInactive = false,
}: {
  showInactive?: boolean;
  useUrlFilters?: boolean;
  enabled?: boolean;
} = {}) => {
  const { userProfile, status: userProfileStatus } = userApi.useUserProfile({
    enabled: enabledProps,
  });
  const enabled = enabledProps && !!userProfile;
  const { setting: filtersResponse } = useUserProfileSetting(
    'additional-fleet-filters',
  );
  const filters = useMemo(() => filtersResponse || [], [filtersResponse]);
  const { fleetDrawerTab } = useUrlSettings('fleetDrawerTab');
  const useUrlFilters =
    typeof useUrlFiltersProps === 'boolean' ? useUrlFiltersProps : (
      fleetDrawerTab === 'filteredOverview' && filters.length > 0
    );
  const region = armadaApi.useFleetRegion();
  const baseFilters: FleetFilter[] = [];

  if (!showInactive) {
    baseFilters.push(inactiveFilter);
  }

  const responseDetails = armadaApi.useVehicles({
    filters: useUrlFilters ? [...baseFilters, ...filters] : baseFilters,
    fleetId: userProfile?.fleet_id || undefined,
    enabled,
    pageSize: 300,
    regions: region ? [region] : [],
    refetchInterval: VEHICLE_REFETCH_INTERVAL,
  });

  const { vehicles: operatedVehicles, status: operatedVehiclesStatus } =
    useAssignedVehicles({ enabled });
  const operatedVehicleIds = useMemo(
    () => operatedVehicles?.map((vehicle) => vehicle.carId),
    [operatedVehicles],
  );

  const vehicles = useMemo(
    () =>
      !responseDetails.result || responseDetails.error ?
        null
      : sortBy(responseDetails.result, (vehicle) =>
          operatedVehicleIds?.includes(vehicle.carId) ?
            `0${vehicle.carName || vehicle.carId}`
          : (
            vehicle.partnerStatus?.issue ||
            (['medium', 'high'] as (AlertSeverityLevel | undefined)[]).includes(
              vehicle.deploymentIssue?.severity,
            )
          ) ?
            `1${vehicle.carName || vehicle.carId}`
            // eventually we want to find a reliable way to not use the summary state here
            // for now, `connected` isn’t reliable enough
          : vehicle.summaryState !== 'INACTIVE' ?
            `2${vehicle.carName || vehicle.carId}`
          : `3${vehicle.carName || vehicle.carId}`,
        ),
    [operatedVehicleIds, responseDetails],
  );

  const status = combineQueryStatuses(
    userProfileStatus,
    operatedVehiclesStatus,
    responseDetails.status,
  );

  return useMemo(
    () => ({
      ...responseDetails,
      vehicles,
      status,
      region,
    }),
    [responseDetails, vehicles, status, region],
  );
};

// RTI-4453 refactor useOwnVehicle and useVehicle
const useVehicle = (
  carId: string | undefined,
  {
    refetchInterval,
    enabled: enabledProps = true,
    keepPreviousData = true,
  }: {
    refetchInterval?: number | false;
    enabled?: boolean;
    keepPreviousData?: boolean;
  } = {},
) => {
  const enabled = enabledProps && !!carId;
  const { result: vehiclePayload, ...rest } =
    useApi<ConciergeVehicles.VehiclesDetail.ResponseBody>(
      conciergePaths.Vehicles.VehiclesDetail(carId ?? ''),
      {
        enabled,
        refetchInterval: refetchInterval ?? VEHICLE_REFETCH_INTERVAL,
        keepPreviousData,
      },
    );

  return {
    // This ignores the cache
    vehicle: (enabled && vehiclePayload) || null,
    ...rest,
  };
};

// RTI-4453 refactor useOwnVehicle and useVehicle
const useOwnVehicle = ({
  refetchInterval,
  enabled: enabledProps = true,
}: { refetchInterval?: number; enabled?: boolean } = {}) => {
  const { userProfile } = userApi.useUserProfile();
  const enabled = enabledProps && !!userProfile?.id;

  const { result, error, status, ...rest } =
    useApi<Operators.GetVehicleByOperatorId.ResponseBody>(
      fleetPaths.Operators.GetVehicleByOperatorId(userProfile?.id ?? ''),
      {
        enabled: enabled,
        refetchInterval: refetchInterval ?? VEHICLE_REFETCH_INTERVAL,
      },
    );

  return {
    ...rest,
    error,
    // This ignores the cache
    vehicle: (enabled && error?.code !== '400' && result) || null,
    // TODO: find a non hacky way to prevent empty vehicle lists from constantly loading
    status:
      !enabled ? 'success'
      : error ? 'error'
      : status,
  };
};

const useFocusedVehicle = ({ enabled }: { enabled?: boolean } = {}) => {
  const [focusedVehicleMin, setFocusedVehicleMin] = useState<
    { carId: string; carName?: string } | undefined
  >();

  const {
    focusedVehicleId: focusedVehicleIdUrl,
    setFocusedVehicleId: setFocusedVehicleIdUrl,
  } = useUrlSettings('focusedVehicleId');

  const { vehicle: focusedVehicleData, ...rest } = useVehicle(
    focusedVehicleMin?.carId,
    {
      enabled,
      keepPreviousData: true,
    },
  );

  useEffect(
    function syncVehicleIdFromUrl() {
      if (focusedVehicleIdUrl === focusedVehicleMin?.carId) return;

      if (focusedVehicleIdUrl) {
        setFocusedVehicleMin({ carId: focusedVehicleIdUrl });
      } else {
        setFocusedVehicleMin(undefined);
      }
    },
    [focusedVehicleIdUrl, focusedVehicleMin?.carId],
  );

  const focusedVehicle = useMemo(
    () =>
      (
        focusedVehicleData &&
        focusedVehicleData.carId === focusedVehicleMin?.carId
      ) ?
        focusedVehicleData
      : undefined,
    [focusedVehicleData, focusedVehicleMin?.carId],
  );

  const handleFocusedVehicleChange = (
    newVehicle?: string | { carId: string; carName?: string },
  ) => {
    if (typeof newVehicle === 'string') {
      setFocusedVehicleMin({ carId: newVehicle });
      setFocusedVehicleIdUrl(newVehicle);
    } else {
      setFocusedVehicleMin(newVehicle);
      setFocusedVehicleIdUrl(newVehicle?.carId ?? null);
    }
  };

  return {
    focusedVehicleId: (focusedVehicle ?? focusedVehicleMin)?.carId,
    focusedVehicleName: (focusedVehicle ?? focusedVehicleMin)?.carName,
    setFocusedVehicle: handleFocusedVehicleChange,
    focusedVehicle,
    ...rest,
  };
};

const useFleetRegion = ({ enabled }: { enabled?: boolean } = {}) => {
  const { userProfile } = userApi.useUserProfile({ enabled });
  const { currentRegion: urlRegion } = useUrlSettings('currentRegion');

  return useMemo(
    () =>
      urlRegion && REGIONS.includes(urlRegion) ?
        urlRegion
      : userProfile?.default_location,
    [urlRegion, userProfile],
  );
};

const useAssignedVehicles = (
  { enabled }: { enabled?: boolean } = { enabled: true },
) => {
  const { userProfile, ...userProfileDetails } = userApi.useUserProfile();
  const params = useMemo(
    () => ({
      filters: JSON.stringify([
        {
          key: 'operatorId',
          check: 'is equal to',
          values: [userProfile?.id],
        },
      ]),
    }),
    [userProfile?.id],
  );

  const { result: operatedVehicles, ...operatedVehicleDetails } = useApi<
    ArmadaVehicles.VehiclesList.ResponseBody,
    ArmadaVehicles.VehiclesList.RequestQuery
  >(armadaPaths.Vehicles.VehiclesList(), {
    enabled: !!userProfile?.id && enabled,
    params,
    refetchOnWindowFocus: 'always',
    refetchInterval: VEHICLE_REFETCH_INTERVAL,
  });

  const isFetching =
    userProfileDetails.isFetching || operatedVehicleDetails.isFetching;
  const error = userProfileDetails.error || operatedVehicleDetails.error;
  const status = combineQueryStatuses(
    userProfileDetails.status,
    operatedVehicleDetails.status,
  );

  return useMemo(
    () => ({
      ...operatedVehicleDetails,
      vehicles: operatedVehicles,
      isFetching,
      error,
      status,
    }),
    [operatedVehicleDetails, operatedVehicles, isFetching, error, status],
  );
};

const useFleetList = () =>
  useInfiniteApi<Fleets.FleetsList.ResponseBody>(
    armadaPaths.Fleets.FleetsList(),
  );

const useFleet = (fleetId?: string | null) => {
  const response = useApi<Fleets.FleetsDetail.ResponseBody>(
    armadaPaths.Fleets.FleetsDetail(fleetId || ''),
    { enabled: !!fleetId },
  );

  return {
    fleet: fleetId ? response.result?.[0] : undefined,
    ...response,
  };
};

export const armadaApi = {
  useVehicles,
  useFleetList,
  useFleet,
  useFleetVehicles,
  useFleetRegion,
  useVehicle,
  useFocusedVehicle,
  useOwnVehicle,
  useAssignedVehicles,
};
