import t from '@motional-cc/fe/tools/translate';
import { OrString } from 'src/interface/utility';
import {
  UNIVERSAL_DATETIME_FORMATS,
  UniversalDateTimeFormat,
} from './constants';

export const renderLocalFormat = (format: OrString<UniversalDateTimeFormat>) =>
  (UNIVERSAL_DATETIME_FORMATS[format as UniversalDateTimeFormat] ??
    t(`date-format.${format}`)) ||
  'UNKNOWN FORMAT';
