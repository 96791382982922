import t from '@motional-cc/fe/tools/translate';
import clsx from 'clsx';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
import Icon from 'src/components/common/Icon';
import { Message as MessageType } from 'src/components/Messages/messages-context';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import { useInterval } from 'src/tools/hooks/useInterval';
import { useUserIsActive } from 'src/tools/hooks/userIsActive';
import { useTimeout } from 'src/tools/hooks/useTimeout';
import './Message.scss';

// the real max timout is 2**31, but going over makes the value 1
const INFINITE_TIMEOUT = 2 ** 30;
const DISMISS_TIMEOUT = inMilliseconds(10, 'seconds');
const INACTIVE_TIMEOUT = inMilliseconds(15, 'seconds');

const iconMessageMap = {
  success: <Icon name="CircleWithTick" className="the-message__icon" />,
  warning: (
    <Icon name="TriangleWithExclamation" className="the-message__icon" />
  ),
  error: <Icon name="DiamondWithExclamation" className="the-message__icon" />,
  info: <Icon name="CircleWithLetterI" className="the-message__icon" />,
};

type Props = {
  message: MessageType;
  onDismiss?: (messageId: string) => void;
  className?: string;
};

function Message({ message, onDismiss, className }: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const [periodDone, setPeriodDone] = useState<number>();
  const userIsactive = useUserIsActive(INACTIVE_TIMEOUT);

  const { startedAt } = useTimeout(
    () => {
      onDismiss?.(message.id);
    },

    !userIsactive || isFocused ? INFINITE_TIMEOUT : DISMISS_TIMEOUT,
  );

  const interval = useCallback(() => {
    if (!startedAt || !userIsactive || isFocused) {
      setPeriodDone(undefined);
      return;
    }
    // 0.15 is added to make the countdown animation reach early rather than late
    setPeriodDone(
      Math.round(
        Math.max(0, Math.min(1, (Date.now() - startedAt) / DISMISS_TIMEOUT)) *
          100,
      ) /
        100 +
        0.1,
    );
  }, [isFocused, startedAt, userIsactive]);
  useInterval(interval, 500);

  const handleDismissClick = () => {
    onDismiss?.(message.id);
  };

  const handleHover = () => {
    setIsFocused(true);
  };

  const handleUnhover = () => {
    setIsFocused(false);
  };

  const customProperties: CSSProperties = useMemo(() => {
    const customProperties: CSSProperties = {};

    if (typeof periodDone === 'number') {
      customProperties['--period-done'] = periodDone;
    }

    return customProperties;
  }, [periodDone]);

  return (
    <div
      role="status"
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      onFocus={handleHover}
      onBlur={handleUnhover}
      style={customProperties}
      className={clsx([
        'the-message',
        className,
        { [`the-message--${message.type}`]: message.type },
        { 'the-message--is-idle': !userIsactive },
      ])}
    >
      <div className="the-message__mark">{iconMessageMap[message.type]}</div>

      {(message.title || message.description) && (
        <hgroup className="the-message__content">
          {message.title && (
            <h3 className="the-message__title">{message.title}</h3>
          )}

          {!message.description ?
            null
          : Array.isArray(message.description) ?
            message.description.map((description, index) => (
              <p key={index} className="the-message__description">
                {description}
              </p>
            ))
          : <p className="the-message__description">{message.description}</p>}
        </hgroup>
      )}

      {message.errorMessage &&
        (!Array.isArray(message.errorMessage) ||
          message.errorMessage?.length > 0) && (
          <details className="the-message__error-message">
            <summary>See error message</summary>
            {Array.isArray(message.errorMessage) ?
              message.errorMessage.map((errorMessage, index) => (
                <p key={`${errorMessage}-${index}`}>{errorMessage}</p>
              ))
            : <p>{message.errorMessage}</p>}
          </details>
        )}

      {onDismiss && (
        <button
          type="button"
          className="the-message__dismiss"
          onClick={handleDismissClick}
          aria-label={t('messages.dismiss')}
        >
          <Icon name="MultiplyFat" />
        </button>
      )}
    </div>
  );
}

export default Message;
