import { ReactNode, useEffect } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

function PageTitle({ title, children }: Props) {
  // Updating the title via <Helmet /> is really unreliable.
  // Not only does it not always update,
  //   but sometimes it picks a random previous title and updates it to that
  useEffect(
    function updateTabTitle() {
      document.title = title;
    },
    [title],
  );

  return children;
}

export default PageTitle;
