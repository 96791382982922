import { Profile } from '@motional-cc/fe/interface/api/user-profile-service';
import { SENTRY_DSN } from '@motional-cc/fe/keys';
import {
  ErrorBoundary,
  init as initSentry,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { Fragment, useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { queryClient } from 'src/api/hooks/service';
import { Env, env } from 'src/api/hosts';
import { userPaths } from 'src/api/user';
import { buildGrfanaUserSessionLink } from 'src/components/common/QuickFeedbackButton/send-feedback';
import useDialog from 'src/components/Dialog/dialog-hook';
import {
  VITE_COMMIT_HASH,
  VITE_DEBUG_ERROR_REPORTING,
  VITE_SENTRY_DSN,
} from 'src/config/env';
import { isObjectWithKeyOfType } from 'src/tools/object/isObjectWithKeyOfType';
import DisconnectedMessage from './DisconnectedMessage';

const sentryDsn = VITE_SENTRY_DSN || SENTRY_DSN;

const ENVS_WITH_ERROR_REPORTING: Env[] = [
  'prd',
  'hil',
  'pre-prd',
  'stg',
] as const;

const ENABLE_ERROR_REPORTING = !!(
  sentryDsn &&
  (VITE_DEBUG_ERROR_REPORTING ||
    // this defaults localhost using stg data to not send sentry errors
    (!globalThis.location.origin.includes('//localhost:') &&
      env &&
      ENVS_WITH_ERROR_REPORTING.includes(env as Env)))
);

if (ENABLE_ERROR_REPORTING) {
  const userProfile: Profile.GetProfile.ResponseBody | undefined =
    queryClient.getQueryData([userPaths.Profile.GetProfile()]);

  initSentry({
    dsn: sentryDsn,
    release: `command-center@${VITE_COMMIT_HASH}`,
    environment: env,
    autoSessionTracking: true,
    beforeSend(event) {
      try {
        return {
          ...event,
          message: `${event.message}\n\n${buildGrfanaUserSessionLink(
            userProfile?.result,
          )}`,
        };
      } catch (e) {
        return event;
      }
    },
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        tracePropagationTargets: ['localhost', 'motional.cc/', /^\//],
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
  });
}

interface Props {
  children: React.ReactNode;
}

function ErrorReportingContext({ children }: Props) {
  const { DialogComponent } = useDialog();

  return (
    <ErrorBoundary
      fallback={(errorData) => (
        <DialogComponent
          isOpen
          cancelText="Refresh the page"
          title={(
            errorData.error.message ||
            (isObjectWithKeyOfType(errorData.error.cause, 'name', 'string') &&
              errorData.error.cause.name) ||
            'An error occurred'
          ).slice(0, 150)}
          description={
            <>
              {ENABLE_ERROR_REPORTING && (
                <p className="dialog-box__description">
                  If the issue persists, please contact support citing the Error
                  ID: {errorData.eventId}
                </p>
              )}

              <details>
                <summary className="dialog-box__description">
                  See more details
                </summary>

                <div className="the-app__error-boundary-description">
                  {errorData.error.message && (
                    <p className="dialog-box__description">
                      {errorData.error.message.split('')[0]}
                    </p>
                  )}

                  <p className="dialog-box__description">Stack trace</p>
                  <p className="dialog-box__description">
                    {errorData.componentStack
                      ?.split('\n')
                      .filter((paragraph) => paragraph.trimEnd())
                      .map((paragraph, paragraphIndex) => (
                        <Fragment key={paragraphIndex}>
                          {paragraph}
                          <br />
                        </Fragment>
                      ))}
                  </p>
                </div>
              </details>
            </>
          }
          onCancelClick={() => {
            document.location.reload();
          }}
        />
      )}
    >
      <DisconnectedMessage />

      {children}
    </ErrorBoundary>
  );
}

export default ErrorReportingContext;
